import React from "react";

const educationContent = [
  {
    year: "2015",
    degree: "Bachelor Degree in Software Engineering - Information Technology Engineering",
    institute: "Damascus University",
    details: `In college, we studied computer science principles for six semesters before choosing a specialization. I decided to follow my passion and specialize in Software Engineering and Information Systems, as I believe in crafting software as an art—building solutions that solve problems efficiently and elegantly. My other options at the time were AI and Network Engineering, but my drive for creating impactful software led me to where I am today.`,
  },

];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
