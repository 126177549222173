import React from "react";

const experienceContent = [
  {
    year: "Jan 2015 – Mar 2016",
    position: " Software Engineer",
    compnayName: "Mega4Tech",
    details: `  A software house I founded to create custom applications for entrepreneurs and business owners, helping them streamline operations and enhance their offerings with tailored digital solutions.

`,
        bullets: [
          `Developed a customized agenda solution for lawyers, including a web and Android application.`,
          `Collaborated with clients and stakeholders to gather requirements and build tailored software solutions.`,
`Implemented a parental control app for Android, helping parents protect children from adult content and excessive mobile usage.`
        ],
  },
  {
    year: "Mar 2016 – Jan 2017",
    position: " Android Software Engineer",
    compnayName: "Duriana Internet Sdn Bhd",
    details: `A startup based in Malaysia, Duriana served as a marketplace for second-hand items and later evolved into 88motors, an advanced platform focused on efficiently selling used cars.

`,
        bullets: [`Implemented new features and refactored code, extracting reusable components into libraries.`,
          `Introduced a buffering algorithm to batch multiple API requests into a single network call, reducing network requests by 20%.`,
          `Reduced memory leaks and performance issues, improving the Free Crash rate from 96% to 99.1%.`,
          `Collaborated with fellow Android engineers to deliver high-quality, scalable projects.`,
        ],

  },
  {
    year: "July 2017 – Oct 2017",
    position: "Mobile Developer",
    compnayName: "Remote – NDA Contractor",
    details: ``,
        bullets: [`Worked as a contract-based mobile developer for three different clients.
`,
`Fixed Android app bugs, added new features, and refactored legacy code.
`,
`Built MVP mobile apps for both Android and iOS platforms.
`,
`Developed new modules and integrated them into existing applications.
`,],

  },
  {
    year: "October 2017 - November 2018",
    position: "Senior Application Developer",
    compnayName: "Strateq Labs",
    details: `Strateq SDN BHD is a medium-sized company that builds innovative software solutions across various sectors such as oil & gas, healthcare, and more. The company also has a lab dedicated to crafting next-gen solutions that harness emerging technologies to improve daily life.`,
        bullets: [`Built POC apps for new products within Strateq companies, with two POCs converting into sales.
`,
`Developed serverless APIs using Firebase and AutoML, cutting development time by 40%.
`,
`Integrated IoT devices with Android on Raspberry Pi to build self-service kiosks.
`,
`Collaborated with development teams to create native Android and iOS apps, delivering MVPs for initial releases.
`,
`Worked closely with the product owner and sales teams to ensure apps met customer requirements.
`
],

  },
  {
    year: "Dec 2018 - April 2021",
    position: "Technical Lead",
    compnayName: "Newswav",
    details: `Newswav is a news aggregator startup where I was one of the first employees. I played a key role in scaling its infrastructure to support hundreds of thousands of concurrent users, adopting elastic cloud architecture, and leveraging AI/ML to enhance news curation.
`,
        bullets: [`Led a team of four engineers using Agile methodologies to ensure timely task delivery.
`,
`Migrated the backend infrastructure from a single server to a scalable, elastic cloud setup on GCP, improving reliability and scalability.
`,
`Developed new Laravel and Node.js APIs, replacing legacy PHP code for better maintainability and onboarding of new developers.
`,
`Implemented 100+ background jobs (Artisan commands) to handle intensive workloads, reducing API latency from 900ms to ~100ms.
`,
`Integrated AutoML services on GCP to process 20,000+ articles daily, improving content personalization.`,
`Developed an in-house ad-serving platform, doubling revenue compared to third-party ad exchanges.
`,
`Built data pipelines to BigQuery and Elasticsearch for efficient analytics and search capabilities.
`,
`Designed BI dashboards using BigQuery and Data Studio to help the product team analyze user behavior and forecast ad revenue.
`,
`Automated CI/CD pipelines with Cloud Build and GitHub Actions, saving 15+ hours of manual work weekly.
`,
`Hired and mentored interns and junior developers, fostering a strong tech team culture.
`
],

  },
  {
    year: "Aug 2021 – May 2022",
    position: "Senior PHP Developer",
    compnayName: "Pushnami",
    details: `Pushnami is the leading push advertising network for affiliates and marketers, operating a large-scale infrastructure that delivers push notifications to hundreds of millions of users monthly in real-time.
`,
        bullets: [`Led a team of two developers in maintaining and optimizing backend services.
`,
`Managed two backend projects handling 1M+ API calls per minute.
`,
`Developed a new data processing pipeline to ingest massive XML files and store them efficiently in Elasticsearch.
`,
`Dockerized legacy projects and migrated some services to AWS ECS for better scalability.
`,
`Built monitoring dashboards to track system health and provide real-time alerts for incidents.
`,
`Implemented modern design patterns (e.g., circuit breaker and Bloom filter) to enhance service reliability and reduce storage costs.
`,
],

  },
  {
    year: "May 2022 – present",
    position: "Software engineer",
    compnayName: "Meta",
    details: `Meta is a tech giant specializing in social media, virtual reality, and AI, owning platforms like Facebook, Instagram, and WhatsApp.`,
        bullets: [
          
],

  },
].reverse();

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
          <ul>
          {val.bullets.map((v, i) => (
            <li className="bullets" key={i} > {v}</li>
          ))}
          </ul>
          
        
        </li>
      ))}
    </ul>
  );
};

export default Experience;
