import { useState } from "react";

const AllBlogData = () => {
  const blogsData = [
    {
      id: 1,
      img: "https://cdn-images-1.medium.com/max/750/1*RLcGyanmtkde8jU2KA0gnA.jpeg",
      title: "How to apply GitOps CD with GKE and Cloud-build in 5 minutes!",
      link: "https://medium.com/google-cloud/how-to-apply-gitops-cd-with-gke-and-cloud-build-in-5-minutes-58455a2bf063?source=rss-fe90c759a21e------2",
      commentor: "Abdul Rahman Babil",
      date: "04 Apr 2021",
      tag: `kubernetes, gcp-security-operations, gitops, google-cloud-build`,
      description1:
        "Learn how to apply GitOps CD with GKE and Cloud Build in just 5 minutes! 🚀 This guide covers using Git as the single source of truth for Kubernetes deployments, ensuring fast, secure, and automated infrastructure management. Plus, explore push vs. pull-based updates and set up a GitOps pipeline with GKE. Check it out!",
    },
    {
      id: 2,
      link: "https://medium.com/google-cloud/stream-millions-of-events-from-your-client-to-bigquery-in-a-serverless-way-part-2-d2913ecee763",
      img: "https://miro.medium.com/v2/resize:fit:1166/format:webp/1*El_UzUeaJLe9hm77e8SbwA.png",
      title: "Stream Millions of Events from Your Client to BigQuery in a Serverless Way, Part #2",
      commentor: "Abdul Rahman Babil",
      date: "21 March 2021",
      tag: `BigQuery, GCP`,
      description1: "Part 2 of this series covers how to efficiently process and load millions of client events into BigQuery using a serverless ETL approach. The article explores Cloud Run-based jobs, batch loading, and optimizing ETL job triggers for scalability. Check out the GitHub repository for implementation details.",
    },
    {
      id: 3,
      link: "https://medium.com/google-cloud/stream-millions-of-events-from-your-client-to-bigquery-in-a-serverless-way-part-1-a38c4f9cd6e4",
      img: "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*57Wowb6Ot7zi-LWPiPejCA.png",
      title: "Stream Millions of events from your client to BigQuery in a Serverless way, Part #1",
      commentor: "Abdul Rahman Babil",
      date: "21 March 2021",
      tag: `BigQuery, GCP`,
      description1:
        "Learn how to handle millions of data events efficiently using Google Cloud’s serverless services. This guide covers building an API to collect raw data and push it to Pub/Sub, followed by running ETL jobs to load events into BigQuery. Scalable, cost-effective, and easy to implement—perfect for startups looking to harness the power of data.",
    },
    {
      id: 4,
      link: "https://medium.com/google-cloud/recommend-personalised-content-for-your-app-users-using-bigquery-239b1398a46a",
      img: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Nx2xLFwMCnFniv75JQYCiw.jpeg",
      title: "How to Inject Humor & Comedy Into Your Brand",
      commentor: "Beker ",
      date: "09 Jan 2021",
      tag: `BigQuery, GCP`,
      description1:
        "A recommendation system can be complex and costly for startups, requiring machine learning models that demand extensive training and maintenance. Instead, a rule-based approach, particularly for news aggregators, offers a simpler alternative. Users receive relevant content based on explicit rules, such as topic preferences, favored publishers, and past reading history. This system, powered by BigQuery, efficiently processes large datasets in offline mode, ensuring scalability. By leveraging precomputed metrics and SQL queries, the model delivers personalized recommendations without the overhead of traditional ML-based solutions.",
    },

   
  ];

  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  return {
    singleData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
  };
};

export default AllBlogData;
